import React from "react";
import { Dialog } from "@puzzle/ui";
import { Button, Text, IconButton, Stack, Box, S } from "ve";
import { Clear } from "@puzzle/icons";
import Link from "components/common/Link";

export function OnboardingCall({
  planName,
  open,
  onClose,
}: {
  planName: string;
  open: boolean;
  onClose: () => void;
}) {
  return (
    <Dialog width={510} open={open}>
      <Dialog.Body
        css={{
          display: "flex",
          flexDirection: "column",
          gap: S["2"],
          padding: `${S["3"]} ${S["4"]} ${S["0"]} ${S["4"]}`,
        }}
      >
        <Stack gap="0">
          <IconButton css={{ marginLeft: "auto" }} onClick={onClose} aria-label="close dialog onboarding call">
            <Clear size={24} />
          </IconButton>
          <Text variant="heading2" color="gray100">
            Thank you for subscribing to the {planName} plan!
          </Text>
        </Stack>
        <Box css={{ lineHeight: S["3"] }}>
          <Text variant="headingM" color="gray300">
            To help you get the most out of the {planName} plan, we recommend booking your
            personalized onboarding call with us and hit the ground running!
          </Text>
        </Box>
      </Dialog.Body>

      <Dialog.Footer align="right">
        <Dialog.Actions>
          <Dialog.Close asChild>
            <Button variant="secondary" onClick={onClose}>
              Maybe later
            </Button>
          </Dialog.Close>
          <Link href={"https://meetings.hubspot.com/luke-frye/puzzle-onboarding"} target="_blank">
            <Button onClick={onClose} variant="primary">
              Schedule a call
            </Button>
          </Link>
        </Dialog.Actions>
      </Dialog.Footer>
    </Dialog>
  );
}
