import { formatMoney } from "@puzzle/utils";

import React, { useState } from "react";
import { ADDONS, RIPPLING_COST_PER_SEAT } from "./plans";
import { SubscriptionBillingCycle, Plan, AddOnNames, AddOn } from "../types";
import { actualPriceAddon, computeSubtotal } from "./utils";

import { S, Separator, Text, color, Box, vars, Button, Stack } from "ve";
import { useActiveCompany } from "components/companies";
import useMonetization from "../useMonetization";
import { useRipplingEmployeeCount } from "components/integrations/rippling/useRippling";
import { RipplingConfirmEmployeesModal } from "./RipplingConfirmEmployeesModal";

const SummaryLineItem = ({
  dataTestId,
  title,
  price,
  isEstimate,
}: {
  dataTestId?: string;
  title?: string | React.ReactNode;
  price: string | null;
  isEstimate?: boolean;
}) => {
  return (
    <Box
      data-testid={dataTestId}
      css={{
        color: color.gray400,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Text variant="bodyM" color="gray400">
        {title}
      </Text>
      <Text variant="bodyM" color="gray400">
        {isEstimate ? "~" : ""}
        {price} / mo
      </Text>
    </Box>
  );
};

interface CheckoutSummaryProps {
  plan?: Plan;
  isPlanCurrentSubscription: boolean;
  basePlanPrice: string | null;
  showDiscountBadge: boolean;
}

export const CheckoutSummary = ({
  plan,
  isPlanCurrentSubscription,
  basePlanPrice,
  showDiscountBadge,
}: CheckoutSummaryProps) => {
  const { currentSubscription, company } = useActiveCompany<true>();
  const { selectedAddons, selectedCycle, loadingStripe, onCheckout } = useMonetization();
  const [isRipplingConfirmEmployeesModalOpen, setIsRipplingConfirmEmployeesModalOpen] =
    useState(false);

  const { ripplingEmployeeCount } = useRipplingEmployeeCount({
    companyId: company.id,
    skip: !selectedAddons.includes(AddOnNames.ADDON_RIPPLING),
  });
  const subtotal = computeSubtotal(
    selectedAddons,
    selectedCycle,
    plan?.id !== currentSubscription ? plan : undefined
  );

  const hasRipplingAddOn = selectedAddons.includes(AddOnNames.ADDON_RIPPLING);
  const ripplingAddOnPrice = () => {
    return ripplingEmployeeCount * RIPPLING_COST_PER_SEAT;
  };

  const monthlyEstimate = computeSubtotal(
    selectedAddons,
    selectedCycle,
    plan?.id !== currentSubscription ? plan : undefined,
    true
  );

  const formattedMonthlyEstimate = hasRipplingAddOn
    ? formatMoney({ currency: "USD", amount: monthlyEstimate + ripplingAddOnPrice() })
    : formatMoney({ currency: "USD", amount: monthlyEstimate });

  const getAddOnValues = (addOn: AddOn) => {
    const addOnPrice = actualPriceAddon(
      selectedCycle || SubscriptionBillingCycle.Monthly,
      addOn.price,
      addOn.priceYear
    );

    const isRippling = addOn.id === AddOnNames.ADDON_RIPPLING;
    if (isRippling) {
      return {
        title: `${addOn.title}*`,
        price: `~${formatMoney({ currency: "USD", amount: ripplingAddOnPrice() })}`,
      };
    }
    return { title: addOn.title, price: `${addOnPrice}` };
  };

  return (
    <Stack
      css={{
        borderRadius: vars.space["1h"],
        padding: vars.space["$3"],
        border: "1px solid rgba(255, 255, 255, 0.12)",
        background:
          "linear-gradient(0deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #1A192A",
        gap: vars.space["2h"],
      }}
    >
      <Text color="gray200" variant="heading3">
        Summary
      </Text>
      {!isPlanCurrentSubscription && (
        <SummaryLineItem
          dataTestId={`summary-${plan?.id}-line`}
          title={`${plan?.title} plan`}
          price={basePlanPrice}
        />
      )}

      {selectedAddons.length > 0 && (
        <Text variant="bodyXS" color="gray400">
          ADD-ONS:
        </Text>
      )}

      {selectedAddons.map((addon) => {
        const selectedAddon = ADDONS.find((a) => a.id === addon);
        if (!selectedAddon) return null;
        const { title, price } = getAddOnValues(selectedAddon);
        return (
          <SummaryLineItem
            dataTestId={`summary-${addon}-line`}
            title={<>&nbsp; &#x2022; {title}</>}
            price={price}
            key={selectedAddon.id}
          />
        );
      })}

      <Separator />
      {hasRipplingAddOn && selectedCycle === SubscriptionBillingCycle.Monthly && (
        <SummaryLineItem
          title="Monthly estimate"
          price={formattedMonthlyEstimate}
          isEstimate={true}
        />
      )}
      {selectedCycle === SubscriptionBillingCycle.Yearly && (
        <SummaryLineItem
          dataTestId="monthly-estimate"
          title="Monthly estimate"
          price={formatMoney(
            {
              currency: "USD",
              amount: monthlyEstimate,
            },
            { truncateValue: false }
          )}
        />
      )}
      <Box
        css={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Text variant="headingM" color="gray50">
          Total due
        </Text>
        <Text variant="headingM" color="gray50">
          {formatMoney({ currency: "USD", amount: subtotal }, { truncateValue: false })}
          {selectedCycle === SubscriptionBillingCycle.Yearly ? " / year" : " / mo"}
        </Text>
      </Box>

      {showDiscountBadge && (
        <Box
          css={{
            padding: `${S["1h"]} ${S["2"]}`,
            borderRadius: vars.radii[2],
            backgroundColor: color.greenBackground,
          }}
        >
          <Text variant="bodyM" color="green600">
            You are saving 15% with yearly subscriptions
          </Text>
        </Box>
      )}

      <Button variant="primary" fullWidth onClick={onCheckout} loading={loadingStripe}>
        {/* TODO (stega): temporarily reverting this pop-up until we get the right employee count to check against
        <Button
        variant="gold"
        fullWidth
        onClick={
          hasRipplingAddOn && ripplingEmployeeCount > 5
            ? () => setIsRipplingConfirmEmployeesModalOpen(true)
            : onCheckout
        }
        loading={loadingStripe}
        > 
      */}
        Continue
      </Button>
      {hasRipplingAddOn && (
        <Text color="gray400" variant="bodyXS">
          * Rippling charges $2 per employee per month for API access. The total monthly cost is
          calculated based on the number of active employees within the month.
        </Text>
      )}
      <RipplingConfirmEmployeesModal
        open={isRipplingConfirmEmployeesModalOpen}
        onOpenChange={() => setIsRipplingConfirmEmployeesModalOpen(false)}
        onContinue={onCheckout}
        numEmployees={ripplingEmployeeCount}
      />
    </Stack>
  );
};
