import { useEffect } from "react";
import { useRouter } from "next/router";
import { hotkeys } from "@puzzle/ui";
import useAppRouter from "lib/useAppRouter";
import { useCmdkStore } from "../cmdk/cmdkStore";
import { navigationShortcuts } from "../cmdk/shortcuts/Navigation";
import { helpShortcuts } from "../cmdk/shortcuts/Help";
import { Shortcut } from "../cmdk/shortcuts/types";
import { useShortcutActions } from "../ShortcutActionsProvider";

export const Hotkeys = () => {
  const router = useRouter();
  const { logout } = useAppRouter();
  const { toggleCmdk: toggleOpen } = useCmdkStore();
  const Actions = useShortcutActions();

  const shortcutsWithUniqueHotkeys: Shortcut[] = [...navigationShortcuts, ...helpShortcuts].filter(
    (shortcut, index, self) =>
      shortcut.hotkey?.trigger &&
      index === self.findIndex((t) => t.hotkey?.trigger === shortcut.hotkey?.trigger)
  );

  const globalShortcuts: Shortcut[] = []; // shortcuts that will fire anywhere
  const restrictedShortcuts: Shortcut[] = []; // shortcuts that won't fire when focused on an input field

  shortcutsWithUniqueHotkeys.forEach((shortcut) => {
    if (shortcut.hotkey?.shouldDisableOnInputs) {
      restrictedShortcuts.push(shortcut);
    } else {
      globalShortcuts.push(shortcut);
    }
  });

  interface HotkeyDictionary {
    [key: string]: (e?: KeyboardEvent) => void;
  }

  // Create a dictionary of hotkeys and their corresponding actions, e.g. { "g i": () => router.push(Route.inbox) }
  const createHotkeyDictionary = (
    shortcuts: Shortcut[],
    Actions: Record<string, () => void>,
    router: ReturnType<typeof useRouter>
  ): HotkeyDictionary => {
    const dict: HotkeyDictionary = {};

    shortcuts.forEach((shortcut) => {
      if (!shortcut.hotkey?.trigger) return;

      // Shortcuts may have both an action and a route or neither.

      if (shortcut.actionOnSelect) {
        const action = Actions[shortcut.actionOnSelect as keyof typeof Actions];
        dict[shortcut.hotkey.trigger] = () => {
          action();
          return shortcut.description; // This is how we see the description in Analytics
        };
      }

      if (shortcut.route) {
        dict[shortcut.hotkey.trigger] = () => {
          router.push(shortcut.route!);
          return shortcut.description; // This is how we see the description in Analytics
        };
      }
    });

    return dict;
  };

  const restrictedHotkeysDict = createHotkeyDictionary(restrictedShortcuts, Actions, router);
  const globalHotkeysDict = createHotkeyDictionary(globalShortcuts, Actions, router);

  // Add the global hotkey for opening the cmd+k modal
  // This is not shown in the cmd+k modal itself
  globalHotkeysDict["Meta+k"] = () => {
    // We removed e.preventDefault() because sometimes we want to fire a simultaneous cmd+k hotkey
    // See the hotkeys in TransactionsPaginated.tsx
    toggleOpen();
    return "Open cmd+k modal"; // This is how we see the description in Analytics
  };

  useEffect(() => {
    const unsubscribeRestrictedHotkeys = hotkeys(window, restrictedHotkeysDict, {
      ignore: { elements: ["input", "textarea", "select"] },
      alwaysAllow: {
        types: ["checkbox", "radio"],
      },
      shouldTrackAnalytics: true,
    });
    const unsubscribeGlobalHotkeys = hotkeys(window, globalHotkeysDict, {
      shouldTrackAnalytics: true,
    });
    return () => {
      unsubscribeRestrictedHotkeys();
      unsubscribeGlobalHotkeys();
    };
  }, [router, logout, globalHotkeysDict, restrictedHotkeysDict]);

  return null;
};
