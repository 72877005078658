import React from "react";
import { UserEducation, ChevronDown } from "@puzzle/icons";
import { Menu } from "@puzzle/ui";
import { Text, Button, S, color } from "ve";
import Analytics from "lib/analytics";

export interface EducationMenuProps {
  feature: string;
  learnMore: string;
  tutorial: string;
}

export const EducationMenu = ({ feature, learnMore, tutorial }: EducationMenuProps) => {
  const menuItemProps = {
    css: {
      padding: S["0h"],
      borderRadius: S["0h"],
      "&:hover, &:focus, &:active": {
        backgroundColor: `${color.white10} !important`,
      },
    },
  } as React.ComponentProps<typeof Menu.Item>;

  const textProps = {
    variant: "bodyS",
    color: "gray200",
    style: { paddingLeft: S["1"] },
  } as React.ComponentProps<typeof Text>;

  const analyticsEvent = {
    feature,
    location: "Help Icon",
  };

  const handleLearnMore = () => {
    window.open(learnMore, "_blank");
    Analytics.featureOnboardingLearnMoreClicked(analyticsEvent);
  };

  const handleTutorial = () => {
    window.open(tutorial, "_blank");
    Analytics.featureOnboardingVideoClicked(analyticsEvent);
  };

  return (
    <div>
      <Menu
        css={{
          padding: S["0h"],
          borderRadius: S["1"],
          width: 146,
          border: `0.5px solid ${color.white30}`,
        }}
        trigger={
          <Button
            variant="minimal"
            suffixElement={<ChevronDown size={11} gradient />}
            css={{
              paddingLeft: S["1"],
              backgroundColor: `inherit !important`,
            }}
          >
            <UserEducation style={{ display: "flex", alignItems: "center" }} />
          </Button>
        }
      >
        <Menu.Item {...menuItemProps} onClick={handleLearnMore}>
          <Text {...textProps}>Learn More</Text>
        </Menu.Item>
        <Menu.Item {...menuItemProps} onClick={handleTutorial}>
          <Text {...textProps}>Watch Tutorial</Text>
        </Menu.Item>
      </Menu>
    </div>
  );
};
