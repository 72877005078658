import { create } from "zustand";

/*

  An explanation of the different states a row can be in:

  FOCUSED: The row is hovered over or tabbed to via keyboard or screen reader.
  Only 1 row may be FOCUSED at a time.

  ACTIVE: This row has been activated and the side drawer is open.
  Only 1 row may be ACTIVE at a time.

  SELECTED: This row has been selected with its checkbox or hotkey.
  Multiple rows (including all in the whole table) may be SELECTED at a time.

  These states are independent and may be combined.
  The same row may be FOCUSED, ACTIVE, and SELECTED at the same time.
  Or some rows might be SELECTED while another is ACTIVE and yet another is FOCUSED.

*/

interface TransactionsTargetType {
  isAnyRowHovered: boolean;
  setIsAnyRowHovered: (val: boolean) => void;
  isAnyRowSelected: boolean;
  setIsAnyRowSelected: (val: boolean) => void;
  isAnyRowActive: boolean;
  setIsAnyRowActive: (val: boolean) => void;
  numOfSelectedRows: number;
  setNumOfSelectedRows: (val: number) => void;
  firstSelectedRowInfo: { vendor: string; amount: string } | null;
  setFirstSelectedRowInfo: (val: { vendor: string; amount: string } | null) => void;
  activeRowInfo: { vendor: string; amount: string } | null;
  setActiveRowInfo: (val: { vendor: string; amount: string } | null) => void;
}

export const useTransactionsTargetType = create<TransactionsTargetType>((set) => ({
  isAnyRowHovered: false,
  setIsAnyRowHovered: (val: boolean) => {
    set({ isAnyRowHovered: val });
  },
  isAnyRowSelected: false,
  setIsAnyRowSelected: (val: boolean) => {
    set({ isAnyRowSelected: val });
  },
  isAnyRowActive: false,
  setIsAnyRowActive: (val: boolean) => {
    set({ isAnyRowActive: val });
  },
  numOfSelectedRows: 0,
  setNumOfSelectedRows: (val: number) => {
    set({ numOfSelectedRows: val });
  },
  firstSelectedRowInfo: null,
  setFirstSelectedRowInfo: (val: { vendor: string; amount: string } | null) => {
    set({ firstSelectedRowInfo: val });
  },
  activeRowInfo: null,
  setActiveRowInfo: (val: { vendor: string; amount: string } | null) => {
    set({ activeRowInfo: val });
  },
}));
