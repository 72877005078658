import React from "react";
import { create } from "zustand";
import { useLocalStorage } from "react-use";

import { useBreakpoint } from "@puzzle/ui";
import { Sidepanel } from "@puzzle/icons";

import { buttonStyle } from "./minimizeSidebarButton.css";

const LOCAL_STORE_KEY = "minimized-sidebar";

interface NavSidebarState {
  isNavMinimized: boolean;
  setIsNavMinimized: (val: boolean) => void;
}

const getInitialState = () => {
  try {
    const stored = localStorage.getItem(LOCAL_STORE_KEY);
    return stored ? JSON.parse(stored) : false;
  } catch {
    return false;
  }
};

export const useNavSidebarStore = create<NavSidebarState>((set) => ({
  isNavMinimized: getInitialState(),
  setIsNavMinimized: (val) => set({ isNavMinimized: val }),
}));

export const useNavSidebarState = () => {
  const [minimizedLocal = false, setMinimizedLocal] = useLocalStorage<boolean>(
    LOCAL_STORE_KEY,
    false
  );
  const { isNavMinimized: isNavMinimizedStore, setIsNavMinimized: setIsNavMinimizedStore } =
    useNavSidebarStore();

  const setIsNavMinimized = (val: boolean) => {
    setMinimizedLocal(val);
    setIsNavMinimizedStore(val);
  };

  return {
    isNavMinimized: isNavMinimizedStore,
    setIsNavMinimized,
  };
};

export const MinimizeSidebarButton = () => {
  const breakpoint = useBreakpoint();
  const { isNavMinimized, setIsNavMinimized } = useNavSidebarState();

  const showButton = isNavMinimized || ["xs", "s", "m"].includes(breakpoint);

  if (!showButton) {
    return null;
  }

  return (
    <button className={buttonStyle} onClick={() => setIsNavMinimized(!isNavMinimized)}>
      <Sidepanel />
    </button>
  );
};
