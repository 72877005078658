import React from "react";
import { Tag } from "@puzzle/ui";
import { vars } from "ve";
import { useTransactionsTargetType } from "components/dashboard/Transactions/hooks/useTransactionsTargetType";
import { formatMoney } from "@puzzle/utils";

interface CmdKLabelProps {
  routeInfo: RouteInfo;
}

interface RouteInfo {
  title: string;
  entityName: string;
  entityNamePlural: string;
}

const CmdKLabel = ({ routeInfo }: CmdKLabelProps) => {
  const { numOfSelectedRows, firstSelectedRowInfo, activeRowInfo } = useTransactionsTargetType();

  const getLabelText = (routeInfo: RouteInfo) => {
    // TRANSACTIONS PAGE LABEL TEXT
    if (routeInfo.title === "Transactions") {
      if (numOfSelectedRows === 1) {
        // e.g.: Transaction -> Cloudflare - ($100.00)
        const { vendor, amount } = firstSelectedRowInfo || activeRowInfo || {};
        const formattedAmount = amount ? formatMoney({ currency: "USD", amount }) : amount;
        return `${routeInfo.entityName} -> <span style="color: ${vars.colors.white}">${vendor} ∙ ${formattedAmount}</span>`;
      }
      if (numOfSelectedRows > 1) {
        // e.g.: 5 transactions
        return `${numOfSelectedRows} ${routeInfo.entityNamePlural.toLowerCase()}`;
      }
      // e.g.: Transactions
      return routeInfo.title;
    }

    // INBOX PAGE LABEL TEXT
    if (routeInfo.title === "Inbox") {
      return routeInfo.title;
    }

    // ALL OTHER ROUTE LABEL TEXT
    else {
      return routeInfo.title;
    }
  };

  return (
    <div style={{ padding: `${vars.space["2"]} ${vars.space["2h"]} 0`, display: "flex" }}>
      <Tag
        variant="neutralSquare"
        color="white05"
        css={{
          padding: `${vars.space["1"]} ${vars.space["1h"]}`,
        }}
      >
        {/*
            Why are we using dangerouslySetInnerHTML here?
            Because the label text can vary drastically depending on the route.
            Each route may have different types of text and styles.
            Their only commonality is that they are all strings that fit within the <Tag> component.
        */}
        <div dangerouslySetInnerHTML={{ __html: getLabelText(routeInfo) }} />
      </Tag>
    </div>
  );
};

export default CmdKLabel;
