import { AlertDialog, Stack, Tag } from "@puzzle/ui";

import React, { useEffect, useMemo } from "react";
import { StepChooser } from "./StepChooser";
import { PLANS, PlanTitle } from "./plans";
import Link from "components/common/Link";
import { AdditionalServices } from "./AdditionalServices";
import { SubscriptionCycleSwitch } from "./SubscriptionCycleSwitch";
import { YearlyDiscountBadge, BasicBadge } from "./Badges";
import { SubscriptionBillingCycle, PricingPlanNames, AddOnNames } from "../types";
import Analytics, { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { actualPrice, getAvailableAddOns, joinWithCommasAnd } from "./utils";

import links from "lib/links";
import useMonetization from "../useMonetization";
import { AddOnBox } from "./AddOnBox";

import { S, Button, Separator, Text, Box } from "ve";
import { useActiveCompany } from "components/companies";
import { CheckoutSummary } from "./CheckoutSummary";
import { IntegrationType } from "graphql/types";

export const RIPPLING_MONTHLY_ONLY = "Rippling add-on is only available on a monthly plan.";

export const ReviewStep = () => {
  const [showAdditionalServices, setShowAdditionalServices] = React.useState(false);
  const {
    selectedPlan,
    selectedCycle,
    setBookkeepingEnabled,
    bookkeepingEnabled,
    isConfirmationModalVisible,
    hideAddonConfirmationModal,
    addonsForConfirmationModal,
    addItemsToStripeSubscription,
    selectedAddons,
  } = useMonetization();
  const { currentSubscription, isOnPaidPlan, company } = useActiveCompany<true>();
  const showAddonPuzzleAI = isPosthogFeatureFlagEnabled(FeatureFlag.ShowAddonPuzzleAI);
  const showRipplingAddOn = isPosthogFeatureFlagEnabled(FeatureFlag.RipplingIntegrationConnection);
  const allowYearlySubscription = isPosthogFeatureFlagEnabled(FeatureFlag.SubscriptionYearly);

  useEffect(() => {
    if (selectedPlan) {
      Analytics.checkoutReviewScreenViewed({ plan: selectedPlan });
    }
  }, [selectedPlan]);

  const plan = useMemo(() => PLANS.find((t) => t.title === selectedPlan), [selectedPlan]);
  const basePlanPrice = actualPrice(selectedCycle || SubscriptionBillingCycle.Monthly, plan);
  const showDiscountBadge =
    selectedCycle === SubscriptionBillingCycle.Yearly && plan?.id !== PricingPlanNames.FREE;

  const visibleAddOns = useMemo(() => {
    const hasRipplingConnection = Boolean(
      company?.integrationConnections?.find((c) => c.type === IntegrationType.Rippling)
    );
    return getAvailableAddOns(showRipplingAddOn && hasRipplingConnection, selectedPlan || "");
  }, [showRipplingAddOn, selectedPlan, company.integrationConnections]);

  const showAddOns = selectedPlan !== PlanTitle.Advanced || showRipplingAddOn;

  const isPlanCurrentSubscription = plan?.id === currentSubscription && isOnPaidPlan;

  return (
    <>
      <AlertDialog
        open={isConfirmationModalVisible}
        onOpenChange={(open) => {
          if (!open) hideAddonConfirmationModal();
        }}
        size="small"
      >
        <AlertDialog.Title showClose>
          Subscribe to {joinWithCommasAnd(addonsForConfirmationModal.map((addon) => addon.title))}
        </AlertDialog.Title>
        <AlertDialog.Body css={{ color: "$gray200" }}>
          Would you like to add{" "}
          {joinWithCommasAnd(addonsForConfirmationModal.map((addon) => addon.title))} to your
          existing subscription?
        </AlertDialog.Body>
        <AlertDialog.Footer>
          <AlertDialog.CancelButton>No thanks</AlertDialog.CancelButton>
          <AlertDialog.ConfirmButton
            onClick={() => addItemsToStripeSubscription(addonsForConfirmationModal)}
            variant="primary"
          >
            Yes
          </AlertDialog.ConfirmButton>
        </AlertDialog.Footer>
      </AlertDialog>
      <Stack
        css={{
          margin: "$10 auto",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          maxWidth: "1120px",
        }}
        gap="5"
      >
        <Stack
          css={{
            gap: "$4",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Text variant="heading1" color="white">
            Review your plan & services
          </Text>
          <Text variant="bodyL" color="gray200">
            Confirm your plan and services selection before checking out
          </Text>
          <div>
            <StepChooser step={2} />
          </div>
          <Stack
            css={{
              flexDirection: "row",
              gap: "$4",
              display: "flex",
            }}
          >
            <Stack
              css={{
                flex: "1 0 800px",
                padding: "$5",
                borderRadius: "$3",
                border: "1px solid $white100",

                background:
                  "linear-gradient(0deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0.04) 100%), #1A192A",
              }}
            >
              {allowYearlySubscription && (
                <>
                  <Box
                    css={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: `-${S["3"]}`,
                    }}
                  >
                    <Text variant="heading3" color="gray200">
                      Your billing cycle
                    </Text>
                    <SubscriptionCycleSwitch />
                  </Box>

                  <Separator css={{ marginTop: S.$4, marginBottom: S.$3 }} />
                </>
              )}
              <Text variant="heading3" color="gray200">
                Your plan
              </Text>
              <Stack
                css={{
                  background: "rgba(255, 255, 255, 0.04)",
                  borderRadius: "$2",
                  border: "1px solid $white100",
                  padding: "$2h",
                  gap: "$1",
                }}
              >
                <Box
                  css={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Text variant="heading2h" color="gray200">
                    <Stack
                      direction="horizontal"
                      css={{ display: "flex", gap: "$2", alignItems: "center" }}
                    >
                      {plan?.title}{" "}
                      {isPlanCurrentSubscription && (
                        <Tag variant="outlined" color="vivid">
                          Active subscription
                        </Tag>
                      )}
                    </Stack>
                  </Text>

                  {plan?.id === PricingPlanNames.FREE ? (
                    <Text variant="heading3" color="gray200">
                      "Free"
                    </Text>
                  ) : (
                    <Box
                      css={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: S["2"],
                      }}
                    >
                      {!isPlanCurrentSubscription && (
                        <>
                          <Text variant="heading3" color="gray200">
                            {basePlanPrice} / month
                          </Text>

                          {showDiscountBadge && <YearlyDiscountBadge css={{ width: "50px" }} />}
                        </>
                      )}
                    </Box>
                  )}
                </Box>

                <Box css={{ display: "flex", flexDirection: "row", gap: S["1"] }}>
                  {plan?.badges.map((badge, i) => (
                    <BasicBadge text={badge} key={i} />
                  ))}
                </Box>
              </Stack>
              {showAddOns && (
                <>
                  <Separator css={{ marginTop: S.$4, marginBottom: S.$3 }} />
                  <Text variant="heading3" color="gray200">
                    Add-ons
                  </Text>
                  <Text variant="headingM" color="gray400">
                    Enhance your current subscription with add-ons from the
                    <Text variant="headingM" color="gray200">
                      {" "}
                      Advanced{" "}
                    </Text>
                    plan. You can add or remove them anytime.
                  </Text>
                  {visibleAddOns.map((addOn, index) => {
                    const preventRipplingSelection =
                      addOn.id === AddOnNames.ADDON_RIPPLING &&
                      selectedCycle === SubscriptionBillingCycle.Yearly;

                    return (
                      <AddOnBox
                        key={index}
                        {...addOn}
                        disabled={preventRipplingSelection}
                        tooltipMessage={preventRipplingSelection ? RIPPLING_MONTHLY_ONLY : ""}
                      />
                    );
                  })}
                </>
              )}
              <Separator css={{ marginTop: S.$4, marginBottom: S.$3 }} />
              <Text variant="heading3" color="gray200">
                Professional Accounting Services
              </Text>
              <div>
                <Text variant="headingM" color="gray400">
                  Don’t want to do it yourself? We will reach out to understand your needs and pair
                  you with the optimal accounting professional from our accounting partner network
                  to manage your Puzzle account and ensure you have a hands-off experience.{" "}
                </Text>
                <Link onClick={() => setShowAdditionalServices((prev) => !prev)}>
                  <Text color="blue500" variant="bodyM">
                    {showAdditionalServices ? "Show less" : "Show more"}
                  </Text>
                </Link>
              </div>

              <Box css={{ display: "flex", flexDirection: "row", gap: S["1"] }}>
                <Button
                  data-testid="bookkeeping-request-button-yes"
                  variant={bookkeepingEnabled ? "primary" : "secondary"}
                  onClick={() => setBookkeepingEnabled(true, { shallow: true })}
                >
                  Yes
                </Button>
                <Button
                  data-testid="bookkeeping-request-button-yes"
                  variant={!bookkeepingEnabled ? "primary" : "secondary"}
                  onClick={() => setBookkeepingEnabled(false, { shallow: true })}
                >
                  No
                </Button>
              </Box>

              {showAdditionalServices && <AdditionalServices />}
            </Stack>
            <Box
              css={{
                flex: "1 0 320px",
              }}
            >
              <CheckoutSummary
                plan={plan}
                isPlanCurrentSubscription={isPlanCurrentSubscription}
                showDiscountBadge={showDiscountBadge}
                basePlanPrice={basePlanPrice}
              />
              <Box
                css={{ display: "flex", flexDirection: "column", gap: S["2"], marginTop: S["2"] }}
              >
                <Link
                  href={links.faqPage}
                  css={{
                    textDecoration: "none !important",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text color="blue500" variant="bodyM">
                    FAQs
                  </Text>
                </Link>
                <Link>
                  <Text id="plan-review-lets-chat" color="blue500" variant="bodyM">
                    Contact sales
                  </Text>
                </Link>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
