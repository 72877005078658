import React from "react";
import { Dialog } from "@puzzle/ui";

import links from "lib/links";

import { Button } from "ve";

import Link from "components/common/Link";

type Props = {
  open: boolean;
  onOpenChange: () => void;
  onContinue: () => void;
  numEmployees: number;
};

export const RipplingConfirmEmployeesModal = ({
  open,
  onOpenChange,
  onContinue,
  numEmployees,
}: Props) => {
  return (
    <>
      <Dialog
        size="small"
        data-testid="rippling-confirm-employees-modal"
        open={open}
        onOpenChange={onOpenChange}
        onOpenAutoFocus={(e: Event) => {
          e.preventDefault();
        }}
      >
        <Dialog.Title>Confirm employees' access to Puzzle</Dialog.Title>
        <Dialog.Body>
          You've granted {numEmployees} employees access to accounting data. If this was not
          intended, please manage user access within Rippling.
        </Dialog.Body>
        <Dialog.Footer align="right">
          <Dialog.Actions>
            <Button variant="secondary" onClick={onContinue}>
              Continue checkout
            </Button>
            <Button>
              <Link href={links.ripplingProvisioning} target="none">
                Learn more
              </Link>
            </Button>
          </Dialog.Actions>
        </Dialog.Footer>
      </Dialog>
    </>
  );
};
