import React, { useMemo } from "react";
import { Alert, Checkbox, Tooltip } from "@puzzle/ui";
import { S, Text, Box, vars } from "ve";

import { YearlyDiscountBadge } from "../Badges";
import useMonetization from "../../useMonetization";
import { AddOn, AddOnNames, SubscriptionBillingCycle } from "../../types";
import { actualPriceAddon } from "../utils";

import {
  containerStyle,
  contentStyle,
  listStyle,
  listeItemStyle,
  headerStyle,
  circledIconStyle,
  titleContainerStyle,
} from "./addonStyles.css";
import { useActiveCompany } from "components/companies";
import { PaidAddOn } from "@puzzle/icons";
import { RIPPLING_COST_PER_SEAT } from "../plans";
import { RIPPLING_MONTHLY_ONLY } from "../ReviewStep";

type AddOnBoxProps = AddOn & {
  CtaComponent?: React.ReactNode;
  showPrices?: boolean;
  disabled?: boolean;
  tooltipMessage?: string;
};

export const AddOnBox = ({
  id,
  icon,
  title,
  price,
  priceYear,
  contentHeading,
  contentBullets,
  CtaComponent,
  showPrices = true,
  disabled = false,
  tooltipMessage = "",
}: AddOnBoxProps) => {
  const { currentAddons, company } = useActiveCompany<true>();
  const { selectedCycle, selectedAddons, toggleAddon } = useMonetization();
  const selected = currentAddons.includes(id) || selectedAddons.includes(id);

  const formattedPrice = actualPriceAddon(
    selectedCycle || SubscriptionBillingCycle.Monthly,
    price,
    priceYear
  );
  const isRippling = id === AddOnNames.ADDON_RIPPLING;

  const cycleText = useMemo(() => {
    const perTimePeriod = "/ month";
    if (isRippling) {
      return (
        <div style={{ display: "flex", alignContent: "center", gap: vars.space["0h"] }}>
          {`$${RIPPLING_COST_PER_SEAT}/employee/mo`} <PaidAddOn />
        </div>
      );
    }
    return perTimePeriod;
  }, [isRippling]);

  const cta = CtaComponent ?? (
    <Tooltip content={tooltipMessage}>
      <div style={{ marginLeft: "auto" }}>
        <Checkbox
          data-testid={`addon-${id}-checkbox`}
          size={14}
          checked={disabled ? false : selected}
          disabled={disabled || currentAddons.includes(id)}
          interactive={false}
        />
      </div>
    </Tooltip>
  );

  const cardContainerStyle = () => {
    if (disabled) return containerStyle.disabled;
    if (selected && !CtaComponent) return containerStyle.active;
    return containerStyle.default;
  };

  return (
    <div
      data-testid={`addon-${id}-box`}
      className={cardContainerStyle()}
      onClick={() =>
        !CtaComponent && !disabled ? currentAddons.includes(id) || toggleAddon(id) : undefined
      }
    >
      <div className={headerStyle}>
        <div className={circledIconStyle}>{icon}</div>
        <div className={titleContainerStyle}>
          <div>
            <Text variant="headingM" color="gray200">
              {title}
            </Text>
          </div>
          {showPrices && (
            <Box
              css={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: S.$1,
              }}
            >
              <Text variant="headingM" color="gray200">
                {formattedPrice}
              </Text>
              <Text variant="headingM" color="gray400">
                {cycleText}
              </Text>
              {selectedCycle === SubscriptionBillingCycle.Yearly && !isRippling && (
                <YearlyDiscountBadge css={{ width: "50px" }} />
              )}
            </Box>
          )}
        </div>

        {cta}
      </div>
      <div className={contentStyle}>
        <Text variant="headingM" color="gray400">
          {contentHeading}
        </Text>
        <ul className={listStyle}>
          <Text variant="bodyM" color="gray400">
            {contentBullets.map((bullet, index) => (
              <li className={listeItemStyle} key={index}>
                {bullet}
              </li>
            ))}
          </Text>
        </ul>
      </div>
      {isRippling && <Alert>{RIPPLING_MONTHLY_ONLY}</Alert>}
    </div>
  );
};
